import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { UmbracoProductFeature } from '@shared/types';

import GatsbyImage from 'common/GatsbyImage';

import './ProductFeaturesMain.scss';
import './ShadowProductFeaturesMain.scss';
import { IPropsProductFeaturesMain } from './models';

const ProductFeaturesMain: FC<IPropsProductFeaturesMain> = ({ items }): ReactElement | null => (
  <div data-test="ProductFeaturesMain" className="product-features-main">
    {items.map((item: UmbracoProductFeature.IData) => (
      <div className="feature-item" key={item?.iconId?.[0]?.properties?.names?.[0] || item.text}>
        <div className="feature-item-icon-wrapper">
          <GatsbyImage image={item.icon} alt={item.iconAlt} className="feature-item-icon" />
        </div>
        <div className="feature-item-info">
          {item?.label ? <strong className="feature-item-title">{item.label}</strong> : null}
          {item?.text ? (
            <p
              className={classNames('feature-item-text', {
                'feature-item-text--bold': !item?.label,
              })}
            >
              {item.text}
            </p>
          ) : null}
        </div>
      </div>
    ))}
  </div>
);

export default ProductFeaturesMain;
